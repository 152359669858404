import React, { useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { H2, H3, H4, P, Small, UL, OL } from '@luigiclaudio/ga-baseline-ui/typography';
import { Container, Row, Col, SiteContentWrapper } from '@luigiclaudio/ga-baseline-ui/layout';
import { ThemeModeContext } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import { AssetRenderer, useHasMounted } from '@luigiclaudio/ga-baseline-ui/helpers';
import MainContainer from '../components/pageLayout/MainContainer';
import EmailLink from '../components/EmailLink';

const PrivacyPolicy = () => {
    const theme = useContext(ThemeContext);
    const { themeMode } = useContext(ThemeModeContext);
    const hasMounted = useHasMounted();
    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;

    let isDarkModeOn = false;

    useEffect(() => {
        if (themeMode === 'darkMode') {
            isDarkModeOn = true;
        }
    }, [themeMode]);

    if (!hasMounted) {
        return null;
    }

    return (
        <DefaultTemplate
            bgStart={renderBgColor}
            bgEnd={renderBgColor}
            content={
                <SiteContentWrapper>
                    <Container isFullHeight>
                        <Row isFullHeight justifyContent="center">
                            <Col xxs={30} sm={20} lg={16}>
                                <MainContainer>
                                    <AssetRenderer
                                        assetPath={`/assets/common/logo-${
                                            isDarkModeOn ? 'dark' : 'light'
                                        }.svg`}
                                        assetHeight={40}
                                    />
                                    {hasMounted && (
                                        <>
                                            <br />
                                            <br />
                                            <H2>Privacy notice</H2>
                                            <Small color="muted">Last updated July 04, 2021</Small>
                                            <br />
                                            <br />
                                            <P lead>
                                                Thank you for choosing to be part of our community
                                                at Hounderly (<b>“Company”</b>, <b>“we”</b>,{' '}
                                                <b>“us”</b>,<b>“our”</b>). We are committed to
                                                protecting your personal information and your right
                                                to privacy. If you have any questions or concerns
                                                about this privacy notice, or our practices with
                                                regards to your personal information, please contact
                                                us at <EmailLink />.
                                            </P>
                                            <P lead>
                                                When you visit our website{' '}
                                                <a href="https://www.hounderly.com">
                                                    https://www.hounderly.com
                                                </a>{' '}
                                                (the “Website”), and more generally, use any of our
                                                services (the “Services”, which include
                                                the Website), we appreciate that you are trusting us
                                                with your personal information. We take your privacy
                                                very seriously. In this privacy notice, we seek to
                                                explain to you in the clearest way possible what
                                                information we collect, how we use it and what
                                                rights you have in relation to it. We hope you take
                                                some time to read through it carefully, as it is
                                                important. If there are any terms in this privacy
                                                notice that you do not agree with, please
                                                discontinue use of our Services immediately.
                                            </P>
                                            <P lead>
                                                This privacy notice applies to all information
                                                collected through our Services (which, as described
                                                above, includes our Website), as well as, any
                                                related services, sales, marketing or events.
                                            </P>
                                            <P lead>
                                                <b>
                                                    Please read this privacy notice carefully as it
                                                    will help you understand what we do with the
                                                    information that we collect.
                                                </b>
                                            </P>
                                            <br />
                                            <br />
                                            <H3>What information do we collect?</H3>
                                            <H4>Personal information you disclose to us</H4>
                                            <P lead>
                                                <i>
                                                    <b>In Short:</b>  We collect personal
                                                    information that you provide to us.
                                                </i>
                                            </P>
                                            <P lead>
                                                We collect personal information that you voluntarily
                                                provide to us when you register on the Website,
                                                express an interest in obtaining information about
                                                us or our products and Services, when you
                                                participate in activities on the Website or
                                                otherwise when you contact us.The personal
                                                information that we collect depends on the context
                                                of your interactions with us and the Website, the
                                                choices you make and the products and features you
                                                use. The personal information we collect may include
                                                the following:Personal Information Provided by You.
                                                We collect email addresses; and other similar
                                                information.Payment Data. We may collect data
                                                necessary to process your payment if you make
                                                purchases, such as your payment instrument number
                                                (such as a credit card number), and the security
                                                code associated with your payment instrument. All
                                                payment data is stored by Stripe. You may find their
                                                privacy notice link(s) here:{' '}
                                                <a href="https://stripe.com/privacy">
                                                    https://stripe.com/privacy
                                                </a>
                                                .
                                            </P>
                                            <P lead>
                                                All personal information that you provide to us must
                                                be true, complete and accurate, and you must notify
                                                us of any changes to such personal information.
                                            </P>
                                            <P lead>
                                                The personal information we collect may include the
                                                following:
                                            </P>
                                            <P lead>
                                                <b>Personal Information Provided by You.</b> We
                                                collect email addresses; and other similar
                                                information.
                                            </P>
                                            <P lead>
                                                <b>Payment Data.</b> We may collect data necessary
                                                to process your payment if you make purchases, such
                                                as your payment instrument number (such as a credit
                                                card number), and the security code associated with
                                                your payment instrument. All payment data is stored
                                                by Stripe. You may find their privacy notice link(s)
                                                here:{' '}
                                                <a href="https://stripe.com/privacy">
                                                    https://stripe.com/privacy
                                                </a>
                                                .
                                            </P>
                                            <P lead>
                                                All personal information that you provide to us must
                                                be true, complete and accurate, and you must notify
                                                us of any changes to such personal information.
                                            </P>
                                            <br />
                                            <br />
                                            <H3>How do we use your information?</H3>
                                            <P lead>
                                                <i>
                                                    <b>In Short:</b> We collect personal information
                                                    that you provide to us.
                                                </i>
                                            </P>
                                            <P lead>
                                                We process your information for purposes based on
                                                legitimate business interests, the fulfillment of
                                                our contract with you, compliance with our legal
                                                obligations, and/or your consent.
                                            </P>
                                            <P lead>
                                                We use personal information collected via our
                                                Website for a variety of business purposes described
                                                below. We process your personal information for
                                                these purposes in reliance on our legitimate
                                                business interests, in order to enter into or
                                                perform a contract with you, with your consent,
                                                and/or for compliance with our legal obligations. We
                                                indicate the specific processing grounds we rely on
                                                next to each purpose listed below.
                                            </P>
                                            <P lead>
                                                We use the information we collect or receive:
                                            </P>
                                            <UL
                                                lead
                                                list={[
                                                    <>
                                                        <b>Request feedback.</b> We may use your
                                                        information to request feedback and to
                                                        contact you about your use of our Website
                                                    </>,
                                                    <>
                                                        <b>
                                                            To enable user-to-user communications.
                                                        </b>{' '}
                                                        We may use your information in order to
                                                        enable user-to-user communications with each
                                                        user’s consent.
                                                    </>,
                                                    <>
                                                        <b>`To manage user accounts.</b>  We may use
                                                        your information for the purposes of
                                                        managing our account and keeping it in
                                                        working order.
                                                    </>,
                                                ]}
                                            />
                                            <br />
                                            <br />

                                            <H3>Will your information be shared with anyone?</H3>
                                            <P lead>
                                                <i>
                                                    <b>In Short:</b> We only share information with
                                                    your consent, to comply with laws, to provide
                                                    you with services, to protect your rights, or to
                                                    fulfill business obligations.
                                                </i>
                                            </P>
                                            <P lead>
                                                We may process or share your data that we hold based
                                                on the following legal basis:
                                            </P>
                                            <P lead>
                                                <b>Consent:</b> We may process your data if you have
                                                given us specific consent to use your personal
                                                information for a specific purpose.
                                            </P>
                                            <P lead>
                                                <b>Legitimate Interests:</b> We may process your
                                                data when it is reasonably necessary to achieve our
                                                legitimate business interests.
                                            </P>
                                            <P lead>
                                                <b>Performance of a Contract:</b> Where we have
                                                entered into a contract with you, we may process
                                                your personal information to fulfill the terms of
                                                our contract.
                                            </P>
                                            <P lead>
                                                <b>Legal Obligations:</b> We may disclose your
                                                information where we are legally required to do so
                                                in order to comply with applicable law, governmental
                                                requests, a judicial proceeding, court order, or
                                                legal process, such as in response to a court order
                                                or a subpoena (including in response to public
                                                authorities to meet national security or law
                                                enforcement requirements).
                                            </P>
                                            <P lead>
                                                <b>Vital Interests:</b> We may disclose your
                                                information where we believe it is necessary to
                                                investigate, prevent, or take action regarding
                                                potential violations of our policies, suspected
                                                fraud, situations involving potential threats to the
                                                safety of any person and illegal activities, or as
                                                evidence in litigation in which we are involved.
                                            </P>
                                            <P lead>
                                                More specifically, we may need to process your data
                                                or share your personal information in the following
                                                situations:
                                            </P>
                                            <UL
                                                lead
                                                list={[
                                                    <>
                                                        <b>Business Transfers. </b>We may share or
                                                        transfer your information in connection
                                                        with, or during negotiations of, any merger,
                                                        sale of company assets, financing, or
                                                        acquisition of all or a portion of our
                                                        business to another company.
                                                    </>,
                                                ]}
                                            />
                                            <br />
                                            <br />
                                            <H3>How long do we keep your information?</H3>
                                            <P lead>
                                                <i>
                                                    <b>In Short:</b> We keep your information for as
                                                    long as necessary to fulfill the purposes
                                                    outlined in this privacy notice unless otherwise
                                                    required by law.
                                                </i>
                                            </P>
                                            <P lead>
                                                We will only keep your personal information for as
                                                long as it is necessary for the purposes set out in
                                                this privacy notice, unless a longer retention
                                                period is required or permitted by law (such as tax,
                                                accounting or other legal requirements). No purpose
                                                in this notice will require us keeping your personal
                                                information for longer than the period of time in
                                                which users have an account with us.
                                            </P>
                                            <P lead>
                                                When we have no ongoing legitimate business need to
                                                process your personal information, we will either
                                                delete or anonymize such information, or, if this is
                                                not possible (for example, because your personal
                                                information has been stored in backup archives),
                                                then we will securely store your personal
                                                information and isolate it from any further
                                                processing until deletion is possible.
                                            </P>
                                            <br />
                                            <br />
                                            <H3>How do we keep your information safe?</H3>
                                            <P lead>
                                                <i>
                                                    <b>In Short:</b> We aim to protect your personal
                                                    information through a system of organizational
                                                    and technical security measures.
                                                </i>
                                            </P>
                                            <P lead>
                                                We have implemented appropriate technical and
                                                organizational security measures designed to protect
                                                the security of any personal information we process.
                                                However, despite our safeguards and efforts to
                                                secure your information, no electronic transmission
                                                over the Internet or information storage technology
                                                can be guaranteed to be 100% secure, so we cannot
                                                promise or guarantee that hackers, cybercriminals,
                                                or other unauthorized third parties will not be able
                                                to defeat our security, and improperly collect,
                                                access, steal, or modify your information. Although
                                                we will do our best to protect your personal
                                                information, transmission of personal information to
                                                and from our Website is at your own risk. You should
                                                only access the Website within a secure environment.
                                            </P>
                                            <br />
                                            <br />
                                            <H3>Do we collect information from minors?</H3>
                                            <P lead>
                                                <i>
                                                    <b>In Short:</b> We do not knowingly collect
                                                    data from or market to children under 18 years
                                                    of age.
                                                </i>
                                            </P>
                                            <P lead>
                                                We do not knowingly solicit data from or market to
                                                children under 18 years of age. By using the
                                                Website, you represent that you are at least 18 or
                                                that you are the parent or guardian of such a minor
                                                and consent to such minor dependent’s use of the
                                                Website. If we learn that personal information from
                                                users less than 18 years of age has been collected,
                                                we will deactivate the account and take reasonable
                                                measures to promptly delete such data from our
                                                records. If you become aware of any data we may have
                                                collected from children under age 18, please contact
                                                us at <EmailLink />.
                                            </P>
                                            <br />
                                            <br />
                                            <H3>What are your privacy rights?</H3>
                                            <P lead>
                                                <i>
                                                    <b>In Short:</b> In some regions, such as the
                                                    European Economic Area (EEA) and United Kingdom
                                                    (UK), you have rights that allow you greater
                                                    access to and control over your personal
                                                    information. You may review, change, or
                                                    terminate your account at any time.
                                                </i>
                                            </P>
                                            <P lead>
                                                In some regions (like the EEA and UK), you have
                                                certain rights under applicable data protection
                                                laws. These may include the right (i) to request
                                                access and obtain a copy of your personal
                                                information, (ii) to request rectification or
                                                erasure; (iii) to restrict the processing of your
                                                personal information; and (iv) if applicable, to
                                                data portability. In certain circumstances, you may
                                                also have the right to object to the processing of
                                                your personal information. To make such a request,
                                                please use the contact details provided below. We
                                                will consider and act upon any request in accordance
                                                with applicable data protection laws.
                                            </P>
                                            <P lead>
                                                If we are relying on your consent to process your
                                                personal information, you have the right to withdraw
                                                your consent at any time. Please note however that
                                                this will not affect the lawfulness of the
                                                processing before its withdrawal, nor will it affect
                                                the processing of your personal information
                                                conducted in reliance on lawful processing grounds
                                                other than consent. 
                                            </P>
                                            <P lead>
                                                If you are a resident in the EEA or UK and you
                                                believe we are unlawfully processing your personal
                                                information, you also have the right to complain to
                                                your local data protection supervisory authority.
                                                You can find their contact details here:{' '}
                                                <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                                                    http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                                </a>
                                                .
                                            </P>
                                            <P lead>
                                                If you are a resident in Switzerland, the contact
                                                details for the data protection authorities are
                                                available here:{' '}
                                                <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                                                    https://www.edoeb.admin.ch/edoeb/en/home.html
                                                </a>
                                                .
                                            </P>
                                            <P lead>
                                                If you have questions or comments about your privacy
                                                rights, you may email us at <EmailLink />.
                                            </P>
                                            <br />
                                            <H4>Account information</H4>
                                            <P lead>
                                                If you would at any time like to review or change
                                                the information in your account or terminate your
                                                account, you can:
                                            </P>
                                            <UL
                                                lead
                                                list={[
                                                    'Log in to your account settings and update your user account.',
                                                ]}
                                            />
                                            <P lead>
                                                Upon your request to terminate your account, we will
                                                deactivate or delete your account and information
                                                from our active databases. However, we may retain
                                                some information in our files to prevent fraud,
                                                troubleshoot problems, assist with any
                                                investigations, enforce our Terms of Use and/or
                                                comply with applicable legal requirements.
                                            </P>
                                            <P lead>
                                                <b>Opting out of email marketing:</b> You can
                                                unsubscribe from our marketing email list at any
                                                time by clicking on the unsubscribe link in the
                                                emails that we send or by contacting us using the
                                                details provided below. You will then be removed
                                                from the marketing email list — however, we may
                                                still communicate with you, for example to send you
                                                service-related emails that are necessary for the
                                                administration and use of your account, to respond
                                                to service requests, or for other non-marketing
                                                purposes. To otherwise opt-out, you may:
                                            </P>
                                            <UL
                                                lead
                                                list={[
                                                    'Access your account settings and update your preferences.',
                                                ]}
                                            />
                                            <br />
                                            <br />
                                            <H3>Controls for do-not-track features</H3>
                                            <P lead>
                                                Most web browsers and some mobile operating systems
                                                and mobile applications include a Do-Not-Track
                                                (“DNT”) feature or setting you can activate to
                                                signal your privacy preference not to have data
                                                about your online browsing activities monitored and
                                                collected. At this stage no uniform technology
                                                standard for recognizing and implementing DNT
                                                signals has been finalized. As such, we do not
                                                currently respond to DNT browser signals or any
                                                other mechanism that automatically communicates your
                                                choice not to be tracked online. If a standard for
                                                online tracking is adopted that we must follow in
                                                the future, we will inform you about that practice
                                                in a revised version of this privacy notice. 
                                            </P>
                                            <br />
                                            <br />
                                            <H3>
                                                Do California residents have specific privacy
                                                rights?
                                            </H3>
                                            <P lead>
                                                <i>
                                                    <b>In Short:</b> Yes, if you are a resident of
                                                    California, you are granted specific rights
                                                    regarding access to your personal information.
                                                </i>
                                            </P>
                                            <P lead>
                                                California Civil Code Section 1798.83, also known as
                                                the “Shine The Light” law, permits our users who are
                                                California residents to request and obtain from us,
                                                once a year and free of charge, information about
                                                categories of personal information (if any) we
                                                disclosed to third parties for direct marketing
                                                purposes and the names and addresses of all third
                                                parties with which we shared personal information in
                                                the immediately preceding calendar year. If you are
                                                a California resident and would like to make such a
                                                request, please submit your request in writing to us
                                                using the contact information provided below.
                                            </P>
                                            <P lead>
                                                If you are under 18 years of age, reside in
                                                California, and have a registered account with the
                                                Website, you have the right to request removal of
                                                unwanted data that you publicly post on the Website.
                                                To request removal of such data, please contact us
                                                using the contact information provided below, and
                                                include the email address associated with your
                                                account and a statement that you reside in
                                                California. We will make sure the data is not
                                                publicly displayed on the Website, but please be
                                                aware that the data may not be completely or
                                                comprehensively removed from all our systems (e.g.
                                                backups, etc.).
                                            </P>
                                            <br />
                                            <H4>CCPA Privacy Notice</H4>

                                            <P lead>
                                                The California Code of Regulations defines a
                                                “resident” as:
                                            </P>
                                            <OL
                                                lead
                                                list={[
                                                    'every individual who is in the State of California for other than a temporary or transitory purpose and',
                                                    'every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose',
                                                ]}
                                            />
                                            <P lead>
                                                All other individuals are defined as
                                                “non-residents”.“If this definition of “resident”
                                                applies to you, we must adhere to certain rights and
                                                obligations regarding your personal information.
                                            </P>
                                            <P lead>
                                                What categories of personal information do we
                                                collect?
                                            </P>
                                            <P lead>
                                                We have collected the following categories of
                                                personal information in the past twelve (12) months:
                                            </P>
                                            <UL
                                                lead
                                                list={[
                                                    <>
                                                        Identifiers such as email address and
                                                        account name
                                                    </>,
                                                ]}
                                            />
                                            <P lead>
                                                {' '}
                                                We may also collect other personal information
                                                outside of these categories instances where you
                                                interact with us in-person, online, or by phone or
                                                mail in the context of:
                                            </P>
                                            <UL
                                                lead
                                                list={[
                                                    'Receiving help through our customer support channels;',
                                                    'Participation in customer surveys or contests; and',
                                                    'Facilitation in the delivery of our Services and to respond to your inquiries.',
                                                ]}
                                            />

                                            <br />
                                            <H4>
                                                How do we use and share your personal information?
                                            </H4>
                                            <P lead>
                                                More information about our data collection and
                                                sharing practices can be found in this privacy
                                                notice.
                                            </P>
                                            <P lead>
                                                You may contact us by email
                                                at accounts@hounderly.com, or by referring to the
                                                contact details at the bottom of this document.
                                            </P>
                                            <P lead>
                                                If you are using an authorized agent to exercise
                                                your right to opt-out we may deny a request if the
                                                authorized agent does not submit proof that they
                                                have been validly authorized to act on your behalf.
                                            </P>

                                            <H4>
                                                Will your information be shared with anyone else?
                                            </H4>
                                            <P lead>
                                                We may disclose your personal information with our
                                                service providers pursuant to a written contract
                                                between us and each service provider. Each service
                                                provider is a for-profit entity that processes the
                                                information on our behalf.
                                            </P>
                                            <P lead>
                                                We may use your personal information for our own
                                                business purposes, such as for undertaking internal
                                                research for technological development and
                                                demonstration. This is not considered to be
                                                “selling” of your personal data.
                                            </P>
                                            <P lead>
                                                Hounderly has not disclosed or sold any personal
                                                information to third parties for a business or
                                                commercial purpose in the preceding 12 months.
                                                Hounderly will not sell personal information in the
                                                future belonging to website visitors, users and
                                                other consumers.
                                            </P>

                                            <H4>Your rights with respect to your personal data</H4>

                                            <b>
                                                Right to request deletion of the data - Request to
                                                delete
                                            </b>
                                            <P lead>
                                                You can ask for the deletion of your personal
                                                information. If you ask us to delete your personal
                                                information, we will respect your request and delete
                                                your personal information, subject to certain
                                                exceptions provided by law, such as (but not limited
                                                to) the exercise by another consumer of his or her
                                                right to free speech, our compliance requirements
                                                resulting from a legal obligation or any processing
                                                that may be required to protect against illegal
                                                activities.
                                            </P>

                                            <b>Right to be informed - Request to know</b>
                                            <P lead>
                                                Depending on the circumstances, you have a right to
                                                know:
                                            </P>

                                            <UL
                                                lead
                                                list={[
                                                    'whether we collect and use your personal information;',
                                                    'the categories of personal information that we collect;',
                                                    'the purposes for which the collected personal information is used;',
                                                    'whether we sell your personal information to third parties;',
                                                    'the categories of personal information that we sold or disclosed for a business purpose;',
                                                    'the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and',
                                                    'the business or commercial purpose for collecting or selling personal information.',
                                                ]}
                                            />

                                            <P lead>
                                                In accordance with applicable law, we are not
                                                obligated to provide or delete consumer information
                                                that is de-identified in response to a consumer
                                                request or to re-identify individual data to verify
                                                a consumer request.
                                            </P>

                                            <b>
                                                Right to Non-Discrimination for the Exercise of a
                                                Consumer’s Privacy Rights
                                            </b>
                                            <P lead>
                                                We will not discriminate against you if you exercise
                                                your privacy rights.
                                            </P>

                                            <b>Verification process</b>
                                            <P lead>
                                                Upon receiving your request, we will need to verify
                                                your identity to determine you are the same person
                                                about whom we have the information in our system.
                                                These verification efforts require us to ask you to
                                                provide information so that we can match it with
                                                information you have previously provided us. For
                                                instance, depending on the type of request you
                                                submit, we may ask you to provide certain
                                                information so that we can match the information you
                                                provide with the information we already have on
                                                file, or we may contact you through a communication
                                                method (e.g. phone or email) that you have
                                                previously provided to us. We may also use other
                                                verification methods as the circumstances dictate.
                                            </P>
                                            <P lead>
                                                We will only use personal information provided in
                                                your request to verify your identity or authority to
                                                make the request. To the extent possible, we will
                                                avoid requesting additional information from you for
                                                the purposes of verification. If, however, we cannot
                                                verify your identity from the information already
                                                maintained by us, we may request that you provide
                                                additional information for the purposes of verifying
                                                your identity, and for security or fraud-prevention
                                                purposes. We will delete such additionally provided
                                                information as soon as we finish verifying you.
                                            </P>

                                            <b>Other privacy rights</b>
                                            <UL
                                                lead
                                                list={[
                                                    'you may object to the processing of your personal data',
                                                    'you may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the data',
                                                    'you can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.',
                                                    'you may request to opt-out from future selling of your personal information to third parties. Upon receiving a request to opt-out, we will act upon the request as soon as feasibly possible, but no later than 15 days from the date of the request submission.',
                                                ]}
                                            />

                                            <P lead>
                                                To exercise these rights, you can contact us by
                                                email at accounts@hounderly.com, or by referring to
                                                the contact details at the bottom of this document.
                                                If you have a complaint about how we handle your
                                                data, we would like to hear from you.  
                                            </P>
                                            <br />
                                            <br />
                                            <H3>Do we make updates to this notice?</H3>
                                            <P lead>
                                                <i>
                                                    <b>In Short:</b> Yes, we will update this notice
                                                    as necessary to stay compliant with relevant
                                                    laws.
                                                </i>
                                            </P>
                                            <P lead>
                                                We may update this privacy notice from time to time.
                                                The updated version will be indicated by an updated
                                                “Revised” date and the updated version will be
                                                effective as soon as it is accessible. If we make
                                                material changes to this privacy notice, we may
                                                notify you either by prominently posting a notice of
                                                such changes or by directly sending you a
                                                notification. We encourage you to review this
                                                privacy notice frequently to be informed of how we
                                                are protecting your information.
                                            </P>
                                            <br />
                                            <br />
                                            <H3>How can you contact us about this notice?</H3>
                                            <P lead>
                                                If you have questions or comments about this notice,
                                                you may email us at <EmailLink />
                                            </P>
                                            <br />
                                            <br />
                                            <H3>
                                                How can you review, update, or delete the data we
                                                collect from you?
                                            </H3>
                                            <P lead>
                                                Based on the applicable laws of your country, you
                                                may have the right to request access to the personal
                                                information we collect from you, change that
                                                information, or delete it in some circumstances. To
                                                request to review, update, or delete your personal
                                                information, please email us at <EmailLink />.
                                            </P>
                                        </>
                                    )}
                                </MainContainer>
                            </Col>
                        </Row>
                    </Container>
                </SiteContentWrapper>
            }
        />
    );
};

export default PrivacyPolicy;
